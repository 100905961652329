@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.loader-animation {
    -webkit-animation: spin 1s infinite ease-in-out;
            animation: spin 1s infinite ease-in-out;
}

@-webkit-keyframes spin { from { transform: rotate(-65deg); } to { transform: rotate(295deg); } }

@keyframes spin { from { transform: rotate(-65deg); } to { transform: rotate(295deg); } }

